<template>
  <header>
    <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
      <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <nuxt-link
          :to="URL_HOME"
          class="flex items-center"
        >
          <svg class="h-6 text-white ml-2" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512 512">
            <path
              d="M511.9 175c-9.1-75.6-78.4-132.4-158.3-158.7C390 55.7 416 116.9 416 192h28.1L327.5 321.5c-2.5-.6-4.8-1.5-7.5-1.5h-48V192h112C384 76.8 315.1 0 256 0S128 76.8 128 192h112v128h-48c-2.7 0-5 .9-7.5 1.5L67.9 192H96c0-75.1 26-136.3 62.4-175.7C78.5 42.7 9.2 99.5.1 175c-1.1 9.1 6.8 17 16 17h8.7l136.7 151.9c-.7 2.6-1.6 5.2-1.6 8.1v128c0 17.7 14.3 32 32 32h128c17.7 0 32-14.3 32-32V352c0-2.9-.9-5.4-1.6-8.1L487.1 192h8.7c9.3 0 17.2-7.8 16.1-17z"
              fill="currentColor"></path>
          </svg>

          <span class="self-center text-lg font-semibold whitespace-nowrap dark:text-white">تامینیفای</span>
        </nuxt-link>

        <nuxt-link
          :to="sprintf(URL_STORE_CART, route.params.slug)"
          class="relative flex items-center lg:order-2"
        >
          <nuxt-icon name="cart" class="text-white text-xl"/>

          <div
            class="absolute left-0 top-0 -translate-y-1/2 -translate-x-1/2 bg-white rounded-full text-xxs px-1"
            v-if="cart_total_quantity"
          >
            {{ cart_total_quantity > 9 ? "9+" : cart_total_quantity }}
          </div>
        </nuxt-link>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { URL_HOME, URL_STORE_CART } from "assets/js/consts/urls.const.js";
import useCart from "assets/js/composables/cart/useCart.js";
import { sprintf } from "sprintf-js";
import { useRoute } from "vue-router";

const route = useRoute();

const {
  cart_total_quantity,
} = useCart();
</script>
